html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: $primary-color;
  //text-decoration: underline;

  @include on-event {
    color: $primary-color;
    text-decoration: underline;
  }
}

::selection,
::-moz-selection {
  background-color: $brand-color;
  color: #000000;
}
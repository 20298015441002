@function asset($base, $type, $path) {
  @return url($base + $type + $path);
}

@function image($path, $base: $base-url) {
  @return asset($base, 'images/', $path);
}

@function font($path, $base: $base-url) {
  @return asset($base, 'fonts/', $path);
}

@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  @return $is-keyword or $is-angle;
}

@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
          to top : bottom,
          to top right : bottom left,
          to right top : left bottom,
          to right : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom : top,
          to bottom left : top right,
          to left bottom : right top,
          to left : right,
          to left top : right bottom,
          to top left : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

%arrows {
  color: $brand-color;
  text-shadow: 2px 2px $light-grey;
}

%text-links {
  color: $primary-color;
  //text-decoration: underline;

  @include on-event {
    background-color: $brand-color;
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
  }
}

%text-links2 {
  color: $primary-color;
  //text-decoration: underline;

  @include on-event {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
  }
}

%fx-hover {
  @include on-event {
    border: 1px solid #fcab11;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
}
.modal {

  .modal-content {
    border-color: $mid-grey;
  }

  .modal-header, .modal-footer {
    padding: 5px 10px;
  }

  .modal-header {
    background-color: $primary-color;
    color: $info;
    .modal-title {
      font-size: 16px;
    }
    .close {
      color: $info;
    }
  }

  .modal-body {
    padding: 10px;
  }

  @include respond-to(large) {

    .modal-dialog {
      margin: 3rem auto;
    }

  }

}
.chooserWidget {
  font-size: 16px;
  margin-bottom: 15px;

  input, select {
    border: 1px solid $brand-color;
    border-radius: 4px;
    height: 44px;
  }

  .chooserCategory {
    @extend .gradient_brand;

    h3 {
      border-bottom: 1px solid $brand-color;
      margin: 0;

      a {
        color: $primary-color;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        line-height: 42px;
        text-decoration: none;
      }
    }

    form {
      padding: 20px 0 28px;
    }

    .dropdownContainer {
      padding: 0;
    }

    .showDevicesBtn {
      padding: 0;

      button {
        color: #FFFFFF;
        display: inline-block;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &:not(:disabled) {
          cursor: pointer;
        }
      }
    }

    &:not(.active) {
      background: $bg-color;

      h3 {
        border-bottom: 1px solid #ffffff;

        a {
          font-weight: normal;
        }
      }

      form {
        display: none;
      }
    }

    .introText {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      margin: 0 -15px 20px;
    }

    select, input {
      margin-bottom: 10px;
    }

  }

  @include respond-to(large) {
    margin-bottom: 0;
    .chooserMain {
      .chooserCategory {

        .dropdownContainer {
          padding: 0 10px 0 0;
        }

        .offset-md-2 {
          padding-right: 0;
        }

        .introText {
          font-size: 28px;
          line-height: 42px;
        }

        select, button, input {
          margin: 0;
        }
      }
    }
  }

}

.searchTabs {
  font-size: 16px;

  * {
    height: 42px;
    line-height: 42px;
  }

  span {
    float: left;
    margin-right: 10px;
  }

  .nav-tabs {
    border: 0 none;

    .nav-item {

      .nav-link {
        background-color: $bg-color;
        border: 0 none;
        border-radius: 0;
        cursor: pointer;
        font-weight: 300;
        padding: 0 18px;
        text-decoration: none;

        &.active {
          background-color: $brand-color-alt;
          border-radius: 2px 2px 0 0;
          color: $primary-color;
          font-weight: bold;
        }
      }
    }
  }
}
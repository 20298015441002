body {
  color: $primary-color;
  font: normal 14px / 21px $text-font-stack;
}

#logo h1 {
  font-size: 0;
}

h1 {
  font-size: 23px;
  margin-bottom: 16px;
  margin-top: 0!important;
  padding: 0;
  font-weight: bold;
}

h2 {
  font-size: 21px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 16px;
}
.relatedProducts {
  h3 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
h3 {
  font-size: 19px;
  line-height: 21px;
  margin-bottom: 16px;
}

h4 {
  font-size: 16px;
  line-height: 20px;
}
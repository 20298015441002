.product-detail-box {
  border: 1px solid #ffffff;
  @include respond-to-max(small) {
    padding: 1rem 0 1rem 0;
  }
  &:hover {
      border: 1px solid #fcab11;
      box-shadow: rgba(17, 17, 26, 0.05) 0 4px 16px, rgba(17, 17, 26, 0.05) 0 8px 32px;
  }
}


.productDetail {
  .partDescription {
    margin-bottom: 20px;

    ul li {
      list-style-image: url('/tpl/_img/arrow_right_solo.gif');
      margin-left: 15px;
    }
  }

  .price {

    .priceHidden, .priceTag {
      color: $brand-color;
      font-weight: bold;
      font-size: 26px;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 10px;
    }

    & > p.small {
      margin: 10px 0 0 5px;
    }

    @include respond-to(large) {
      margin-top: 0;
    }

  }

  .parts {
    .partNumbers {
      padding-top: 15px;

      #searchParts {
        border-right: 0 none;
      }

      .input-group-text {
        padding: 0;

        &, button {
          background-color: transparent;
        }

        button {
          border: 0 none;
          padding: 6px 10px;
        }
      }
    }

    .partListing {
      border: 1px solid $light-grey;
      padding: 5px 10px;

      .partList {
        line-height: 20px;
        overflow: hidden;
        font {
          line-height: 100%;
        }
      }

      .showLess {
        display: none;
      }

      .showMoreParts {
        display: none;
        text-align: center;
        //text-decoration: underline;
        cursor: pointer;
        & > span {
          //@extend %text-links;
          color: #1E262C;
          cursor: pointer;
          text-decoration: none;
        }
        border: 2px solid #DDE2E6!important;
        margin: 5px;
        &:hover {
          text-decoration: none;
          border: 2px solid #FCAB11 !important;
        }

        &._top {
          //border-bottom: 1px solid $light-grey;
          //margin: 0 -10px 5px;
          //padding-bottom: 5px;
          display: none;
          margin-bottom: 1rem;
        }

        &._bottom {
          //border-top: 1px solid $light-grey;
          //margin: 5px -10px 0;
          //padding-top: 5px;
          margin-top: 1rem;
        }


      }
    }
  }
}

.pds-gallery {

  .pds-gallery-image-description {
    margin-top:0.5rem;
    min-height: 1rem;
  }

  .pds-gallery-active {
    display: inline-block;
    img {
      //max-height: 270px;
      //max-width: 330px;
    }
    .pds-gallery-navigation {
      cursor: pointer;
      line-height: 270px;
      position: absolute;
      text-align: center;
      width: 50px;
      .icon {
        color: #FFF;
        font-size: 24px;
        font-weight: bold;
        text-shadow: 1px 3px rgba(0,0,0,0.5);
      }
      &:hover {
        .icon {
          color: $brand-color;
        }
      }
      &._next {
        margin-left: -50px;
      }
    }
    .openProductGallery {
      cursor: pointer;
      img {
        border: 1px solid #ffffff;
      }
      &:hover {
        img {
          border: 1px solid #fcab11;
          box-shadow: rgba(17, 17, 26, 0.05) 0 4px 16px, rgba(17, 17, 26, 0.05) 0 8px 32px;
        }

      }
    }
  }
  .pds-gallery-thumbnails {
    padding: 10px 0;
    a {
      cursor: pointer;
      img {
        border: 2px solid transparent;
        height: 96px;
        width: 128px;

      }
      &.active {
        img {
          border-color: $brand-color;
        }
      }
      &:hover {
        img {
          border: 1px solid #fcab11;
          box-shadow: rgba(17, 17, 26, 0.05) 0 4px 16px, rgba(17, 17, 26, 0.05) 0 8px 32px;
        }
      }
    }
  }
}

#lightboxOverlay {
  max-width:100wv;
}

#lightbox {

  .lb-nav {
    overflow: hidden;
    @include respond-to(large) {
      overflow: visible;
    }
    a {
      @extend %arrows;
      color: $brand-color;
    }
    .lb-prev {
      margin-left: -20px;
      @include respond-to(large) {
        margin-left: -100px;
      }
      opacity: 1!important;
    }
    .lb-next {
      margin-right: -20px;
      @include respond-to(large) {
        margin-right: -100px;
      }
      opacity: 1!important;
    }
  }
}

.description-title {
  font-size: 1.4em;
  font-weight: bold;
}
#contactForm {
  margin: 15px -15px;
  padding: 15px;

  input[type="text"] {
    margin-bottom: 10px;
  }

  ::placeholder {
    color: $mid-grey;
    font-size: 14px;
    line-height: 16px;
  }
}

#dsgvo {
  font-weight: bold;
}

.form_business {
  .private {
    display: none;
  }
}

.form_private {
  .business {
    display: none;
  }
}

.direct-order, .warranty {

  #order-form-confirmation {
    a.print-order-preview-link {
      font-size: 2em;
      background-color: $brand-color;
    }
  }

  .content {
    padding: 20px;

    .with-bg {
      margin: 10px -20px 0;
    }
  }

  select.form-control option:disabled,
  select.form-control option:invalid {
    color:black!important;
  }
  select:not(:valid) {
    color:black!important;
  }

  .form-control {
    margin-bottom: 10px;
  }

  .input-group {
    margin-bottom: 10px;

    .form-control {
      margin-bottom: 0;
    }

    .input-group-text {
      padding: 0 12px;
      font-size: 14px;
    }
  }

  .form-check {
    height: 22px;
    line-height: 22px;
    margin-bottom: 20px;
    padding-left: 0;
    position: relative;

    .form-check-label {
      cursor: pointer;
      margin-left: 40px;
    }

    .form-check-input {
      cursor: pointer;
      height: 22px;
      margin: 0;
      width: 22px;
    }
  }

  .form-group {
    margin-bottom: 10px;
    & > label {
      margin-bottom: 0;
    }
  }


  .infoBox {
    background-color: $info;
    border: 1px solid $brand-color;
    color: $brand-color;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    padding: 8px 12px;

    & > span {
      color: $primary-color;
      font-weight: bold;
    }
  }

  h3:not(:first-of-type) {
    margin-top: 24px;
  }

  fieldset {
    margin: 16px 0 0;

    legend {
      @extend h3;
      margin-bottom: 8px;
    }
  }

  .agreements {

    label {
      display: block;
    }

    input {
      margin-left: -30px;
      margin-top: 4px;
      position: absolute;
    }

    .required {
      font-weight: bold;
      padding-left: 40px;
    }
  }

  @include respond-to(large) {
    .content {
      padding: 10px;

      .with-bg {
        margin: 0;
      }
    }

    .inputFields {
      min-height: 750px;
    }

    .agreements > .content {
      padding-left: 40px;
    }
  }

}

#formChooser {
  .form-check-label {
    margin-left: 10px;
  }

  .form-check-input {
    height: inherit;
    width: inherit;
  }
}

#leihtacho {
  display: block;
}
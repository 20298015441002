.navbar {
  background-color: $primary-color;
  padding: 0;

  .navbar-wrapper {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .navbar-nav {
    flex-wrap: wrap;
    display: flex;

    .nav-item {
      border-top: 1px solid $light-grey;
      padding: 0 2rem;
      //padding: 1rem;

      &:last-of-type {
        border-bottom: 1px solid $light-grey;
      }

      .nav-link {
        font-size: 1.4em;
        font-weight: 300;
        line-height: 50px;
        padding: 0;
        text-transform: uppercase;
      }

      .nav-dropdown {
        display: none;
      }

      a {
        color: $mid-grey;
        text-decoration: none;
      }

      &.active {
        & > a {
          font-weight: 400;
        }
      }

      &.open {
        margin-right:1px;
        border-top: 1px solid orange;
        border-bottom: 1px solid orange;

        .nav-link {
          background-color: #F5F5F5;
          color: #000;
          font-weight: bold;
          margin: 0 -23px 10px;
          padding: 0 25px;
        }

        .nav-dropdown {
          display: block;
          margin: 30px 0;


          h3 {
            font-size: 1.2em;
            //font-weight: bold;
            margin-bottom: 1rem;
          }

          li {
            padding:0.5rem;
            border-bottom: 1px solid $light-grey;
          }
          a {
            font-size: 1.2em;
            opacity: 0.9;
            //text-decoration: underline;
            text-transform: none;
            &:focus, &:active {
              background-color: #FCAB11!important;
              color: #1E262C!important;
            }
          }

          & > a {
            margin-bottom: 20px;
          }

          .moreInformation {
            list-style: none;
            line-height: 150%;
          }

          .form-group {
            margin-bottom: 30px;
          }

          .goToCategory {
            margin-bottom: 20px;
          }
        }
      }
    }

  }

  &.mobile-offcanvas {
    background-color: #FFFFFF;
    border-radius: 0;
    display: block;
    font-size: 14px;
    height: 100%;
    left: 0;
    line-height: 14px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 420px;
    padding: 0;
    position: fixed;
    visibility: hidden;
    text-transform: uppercase;
    transform: translateX(-100%);
    top: 0;
    transition: visibility .2s ease-in-out, transform .2s ease-in-out;
    width: 90%;
    z-index: 1200;

    .offcanvas-header {
      background-color: $primary-color;
      color: #FFFFFF;
      display: block;
      font-size: 16px;
      line-height: 50px;
      padding: 5px 25px;

      .btn-close {
        color: #FFFFFF;
        line-height: 50px;
        padding: 0;
      }
    }

    .btn {
      text-transform: none;
    }

    .flags {
      padding: 15px 25px;

      a {
        margin-right: 10px;

        img {
          height: 18px;
        }
      }
    }

    #directOrder {
      margin: 0 25px 15px;
    }
  }

  &.show {
    visibility: visible;
    transform: translateX(0);
  }
}

.screen-overlay {
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity .2s linear, visibility .1s, width 1s ease-in;
}

.screen-overlay.show {
  transition: opacity .5s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

@include respond-to(large) {
  .navbar {

    .navbar-nav {

      .nav-item {
        border: 0 none;
        padding: 0;
        z-index: 20;

        & > a {
          &, &:hover, &:active, &:visited, &:focus {
            color: #FFFFFF;
            cursor: pointer;
          }
        }

        &:first-of-type {
          margin-left: -15px;
        }

        &:last-of-type {
          border-bottom: 0 none;
        }

        &.active {
          background-color: $brand-color;

          a {
            color: #000000;
          }
        }

        .nav-link {
          font-size: 16px;
          line-height: 42px;
          padding: 0 15px;
        }

        .nav-dropdown {
          background-color: #FFFFFF;
          display: none;
          left: 0;
          min-height: 244px;
          padding: 28px 0;
          position: absolute;
          width: 100%;

          h3 {
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            text-transform: uppercase;
          }

          a {
            border: none;
            color: $primary-color;
            font-size: 14px;
            //text-decoration: underline;

            &:hover {
              background-color: $brand-color;
              color: #000000;
              text-decoration: none;
            }
          }

          .row {
            margin-left: -30px;
            margin-right: -30px;
          }

          .moreInformation {
            ul {
              padding-left: 0;

              li {
                display: block;
                list-style: none;
              }
            }
          }
        }

        .nav-dropdown {

         // -webkit-animation: fadeout 0.1s ease;
        //  animation: fadeout 0.1s ease;
/*
          @-webkit-keyframes fadeout {
            100% {opacity: 0;}
            50% { opacity: 0.5; display: flex;}
            0% {opacity: 1; display: flex;}
          }
          @keyframes fadeout {
            100% {opacity: 0;}
            50% { opacity: 0.5; display: flex;}
            0% {opacity: 1; display: flex;}
          }
*/
        }

        &:hover .nav-dropdown {
          &.show {
            display: flex;
         //   -webkit-animation: fadein 0.1s ease;
          //  animation: fadein 0.1s ease;
          }
/*
          @-webkit-keyframes fadein {
            100% {opacity: 1;}
            50% { opacity: 0.5; }
            0% {opacity: 0;}
          }

          @keyframes fadein {
            100% {opacity: 1;}
            50% { opacity: 0.5; }
            0% {opacity: 0;}
          }
*/
        }

      }

      #backdrop {
        background-color: rgb(30, 38, 44);
        left: 0;
        height: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        width: 0;
        z-index: 10;

        @include respond-to(large) {
          top: 356px;
        }

        &.active {
          height: 100%;
          opacity: 0.6;
          width: 100%;
        }
      }

      .brandsList {
        border-right: 1px solid $light-grey;

        & > div {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 300px;
        }
      }

      &:hover {
        .nav-item {
          &.active {
            background-color: transparent;
            border-bottom: 3px solid $brand-color;
            height: 42px;

            & > a {
              color: #FFFFFF;
            }
          }

          &:hover {
            background-color: $brand-color;

            & > a {
              color: $primary-color;
              //font-weight: 400;
            }
          }
        }
      }
    }
  }
}
.header {
  padding: 15px 15px 0px 0px;
  .row {
    margin-left: 0!important;
    margin-right: 0!important;
  }
  #logo img {
    height: 38px;
    max-width: 100%;
    @include respond-to(large) {
      margin-left: -15px;
    }
  }
  #header-search-mobile {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-right: 0!important;
  }

  .header-search {
    .input-search-wrapper {
      flex-grow: 1;
      height:38px;
      input {
        height:38px;
      }
    }
    .input-group-append {
      .input-group-text {
        padding: 0px!important;
        display: flex;
        align-items: stretch;
        button {
          display: flex;
          padding: 5px 10px 5px 10px!important;
          border:0px!important;
          align-items: center;
          span.icon {
            //padding: 0.5rem;
          }
        }
      }
    }
  }

  .icon {
    //font-weight: bold;
  }

  @include respond-to(large) {
    height: 70px;
    margin-bottom: 0;
    padding: 16px 0 0;
    #directOrder {
      display: inline-block;
    }
  }

  #contact {
    color: $mid-grey;
    text-align: right;
    justify-content: flex-end;

    a.btn {
      display: inline-block;
    }

    .telephoneNo {
      color: $primary-color;
      font-weight: bold;
      margin-left: 10px;
      white-space: nowrap;
    }
  }
}

.preHeader {
  color: $mid-grey;
  font-family: $text-font-stack-alt;
  font-size: 14px;
  line-height: 30px;
  height: 30px;
  margin: 0 auto;

  & > .container {
    padding: 0;
  }

  .icon {
    margin-right: 5px;
  }
}

.sticky-top {
  background-color: #FFFFFF;
}

#mainNavigation {
  top: 70px;

  @include respond-to(large) {
      min-height:42px;
      background-color: $primary-color!important;
      width:100%;
  }

}

.flags a {
  opacity: 0.5;
  text-decoration: none;

  &.active {
    cursor: default;
    opacity: 1;
    pointer-events: none;
  }
}

@include respond-to(large) {
  .header #contact {
    text-align: left;
  }
}

.alertBanner {
  background-color: $primary-color;
  color: $info;
  padding: 5px 10px;
  top: 78px;
  z-index: 200;
  p {
    margin-bottom: 0;
  }

  @include respond-to(large) {
    padding: 5px 0;
    top: 112px;
    p {
      margin: 0 auto;
      width: 1180px;
    }
  }
}
.breadcrumb {
  background: none;

  span {
    color: $mid-grey;
    text-decoration: none;
  }

  a {
    @extend %text-links;
    color: $mid-grey;
    text-decoration: none;
  }

  @include respond-to(large) {
    flex-wrap: wrap;
    max-width: 1180px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: 100%;
    min-height: unset!important;
  }

    min-height:66px;


}

.breadcrumb-item+.breadcrumb-item::before {
  color: $brand-color;
  content: '>';

}
.breadcrumb-item::before {
  padding-right: 0!important;
}

.breadcrumb-item a {
  margin-left: 5px!important;
}
span.input-search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
span.input-search-wrapper span {
  position: absolute;
  display: block;
  right: 20px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fff;
  background-color: #ccc;
  font: 13px monospace;
  text-align: center;
  line-height: 1em;
  cursor: pointer;
}
span.input-search-wrapper input {
  padding-right: 18px;
  box-sizing: border-box;

  &::placeholder {
    opacity: 0.5; /* Firefox */
  }
  &:focus {
    &::placeholder {
      opacity: 1; /* Firefox */
    }
  }

}
.search-result-container {

  padding: 0!important;

  .search-result {
    margin: 1rem 1rem 1rem 1rem;
  }


  .sre-message {
    padding: 1px;
    font-weight: bold;
  }

  .qs-iadd222222222 {
    color:blue;
    cursor:pointer;
  }
  .result-entry {
    margin-top:1rem;
    padding:0rem 1rem 1rem 1rem;
    //height:280px!important;
    border:1px solid #DDE2E6;
    cursor: pointer;
    background-color: #fff;

    &:hover {
      .btn {
        border: 2px solid #FCAB11;
      }

    }


    .sre-1 {
      display:flex;
      padding-top:1rem;
      flex-direction: column;
      @include respond-to(medium) {
        flex-direction: row;
        align-items: center;
      }


    }
    .sre-content-wrap {
      display:flex;
      flex-direction: column;
      flex-grow: 1;
      padding-top:1rem;

      .category {
        font-weight: bold;
        font-size: 1.4em;
        @include respond-to(medium) {
          font-size: 1.6em;
        }
        padding: 1rem 1rem 0 1rem;
      }

      .action {
        font-weight: bolder;
        font-size: 1.6em;
        line-height: 2.2rem;
        @include respond-to(medium) {
          font-size: 1.8em;
        }
        padding: 1rem 1rem 1rem 1rem;
      }


    }

    .sre-vmodel-wrap {
      display:flex;
      font-size: 1.2em;
      @include respond-to(medium) {
        font-size: 1.4em;
      }
      padding: 1rem 1rem 1rem 1rem
    }

    .sre-image-wrap {
      display: flex;
      justify-content: center;
      @include respond-to(medium) {
        padding-right: 2rem;
      }
      @include respond-to(large) {
        padding-right: 8rem;
      }
    }

    .sre-button-wrap {
      display: flex;
      align-self: center;
      @include respond-to(medium) {
        align-self: flex-end;
      }
    }

    .cTitle {
      margin-top:1rem;
    }

    @extend %fx-hover;

    .sre-vmodel-info {
      font-size: 1.2em;
      font-weight: 500;
    }

    .sre-image {

      @include respond-to(large) {
        min-width: 200px;
        height:160px;
        max-height: 160px;
      }
    }
  }
  .btn {
    font-weight: bolder!important;
    padding: 1rem 5rem 1rem 5rem;
    border: 2px solid #DDE2E6;
    &:hover {
      border: 2px solid #FCAB11;
    }

  }
}
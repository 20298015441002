html  {
  scrollbar-gutter: stable;
}
body {
  background-color: $bg-color;
}

main {
  background-color: #FFFFFF;
  padding-top: 10px;

  h4 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  @include respond-to(large) {
    padding-top: 20px;
    & > .container {
      padding: 0 0 15px;
    }

    h2 {
      margin: 0 0 20px;
    }
  }
}

.content {

  .with-bg {
    background-color: $bg-color;
    margin: 10px -15px;
    padding: 15px;

    &.col-12 {
      margin: 15px 0;
    }

    &.row {
      padding: 15px 0;
    }
  }

  img {
    max-width: 100%;
  }

  .caption {
    color: $mid-grey;
    display: block;
    margin-top: 8px;
    text-align: center;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      line-height: 150%;
      list-style-image: url('/tpl/_img/arrow_right_solo.gif');
      margin: 20px 0 20px 15px;

      &.productCarouselCategory {
        list-style-image:none!important;
      }
    }

    &.checklist li {
      list-style: none;
    }
  }

  .sumUp {
    border: 1px dotted $light-grey;
    height: 100%;
    ul {
      list-style-type: circle;
      margin: 0;
      padding: 0 15px;
      li {
        margin: 0;
      }
    }
  }

  @include respond-to(large) {
    .with-bg {
      margin: 0;
      &.col-12 {
        margin: 0;
      }
    }

    .col-12:not(.with-bg) {
      margin-top: 15px;
    }

    .with-bg.row {
      background-color: $bg-color;
      padding: 30px 15px;
      margin: 10px -30px;
    }
  }

}
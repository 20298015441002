.productCarousel {

  .productCarouselCategory {
    height: 42px;
    margin: 0 0 1px 0;

    .productCarouselNavigation {
      border-bottom: 0 none;
      margin-bottom: 19px;
      background-color: $primary-color;
      border-radius: 0;
      color: #FFFFFF;
      display: block;
      font-size: 16px;
      line-height: 42px;
      padding: 0 18px;
      text-align: center;
      text-decoration: none;

      &:active, &:hover, &:visited, &:focus {
        border-color: transparent;
        color: #FFFFFF;
      }

    }
  }

  .tab-content {
    display: none;
    overflow-x: hidden;
    padding-bottom: 15px;

    &.active {
      display: block;
    }

    .productCarouselImage {
      img, object {
        //height: 165px;
      }
    }

    .productCarouselDetail {
      display: inline-block;

      & > div {
        border: 1px solid $light-grey;
        color: $dark-grey;
        font-size: 16px;
        //height: 335px;
        line-height: 20px;
        padding: 30px 20px 26px;
        text-align: center;
        white-space: normal;

        @extend %fx-hover;

        .productCarouselName {
          height: 80px;
          margin-bottom: 20px;
          padding-top:2rem;
          overflow-y: hidden;
          text-overflow: ellipsis;
        }

        a {
          text-decoration: none;

          &.btn {
            border: 2px solid $light-grey;

            &:hover {
              border-color: $brand-color;
            }
          }
        }
      }
    }
  }

  .productCarouselList {
    display:inline;
    .productCarouselCategory.active {
      height: auto;



      .productCarouselNavigation {
        background-color: $brand-color;
        color: $primary-color;

      }

      .tab-content .productCarouselDetail {
        display: inline-block;
      }
    }



    &:hover {
      .productCarouselCategory {
        cursor: pointer;

        &.active .productCarouselNavigation {
          background-color: $primary-color;
          border-bottom: 3px solid $brand-color;
          @include respond-to-max(small) {
            background-color: $brand-color;
            color: $primary-color;
          }
          color: #FFFFFF;
          height: 42px;
        }

        .productCarouselNavigation:hover {
          background-color: $brand-color;
          color: $primary-color;
        }
      }
    }
  }

  @include respond-to(large) {
    .productCarouselList {
      margin-bottom: 20px;
      display:inline-flex;
    }

    .productCarouselCategory {
      display: inline-block;

      &.active {
        .productCarouselNavigation {
          margin-bottom: 0;
        }
      }

      .productCarouselNavigation {
        margin-right: 1px;
      }
    }

    .tab-content {
      .productCarouselDetail {
        & > div {
          height: 360px;

          .productCarouselName {
            height: 80px;
          }
        }
      }
    }
  }

}
.productListing {

  h1 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    @include respond-to(large) {
      margin: 0 0 20px;
    }
  }

  .listingFilter {
    background-color: $bg-color;
    padding: 15px 20px;
    z-index:1010;
    top:60px;
    @include respond-to(large) {
      top: 110px;
    }


    .col-12 {
      line-height: 35px;
      margin-top: 0;
      justify-content: space-between;
    }

    h2 {
      font-size: 1em;
      height: 35px;
      line-height: 35px;
      margin-bottom: 0;
      display: inline!important;
    }

    #removeFilter {
      background-color: $mid-grey;
      color: #ffffff;
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      padding: 10px;
      border-radius: unset!important;
      margin-left: 2rem!important;
      span {
        color: $brand-color;
        font-size: 12px;
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }

  .listingResults {
    margin: 0 10px;

    .productEntry {
      padding: 5px;

      .peMain {
        border: 1px solid $light-grey;
        border-radius: 0;
        padding: 10px;
        text-align: center;

        @extend %fx-hover;

        & > a {
          border-color: $light-grey;
          text-decoration: none;
          &:hover {
            border-color: $brand-color;
          }
        }
      }

      .peImage {
        margin-bottom: 5px;
        width: 100%;
        img, object {
          //width: 240px;
        }
      }

      .peTitle {
        height: 65px;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }

      a {
        border-width: 2px;
        margin: 0 auto;
        max-width: 300px;
        text-decoration: none;
      }

    }

  }

  @include respond-to(large) {
    .listingResults {
      margin: 0 5px;

      .productEntry {
        padding: 10px;

        .peMain {
          padding: 20px;
        }

        .peImage {
          margin-bottom: 10px;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }

  .filter-vehicle-model {
      margin-top: 3rem;
      padding-bottom: 3rem;

    .vehicle-model-head {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .vehicle-model-links ul {
      display:grid;
      grid-template-columns: 1fr;
      @include respond-to(medium) {
        grid-template-columns: 1fr 1fr;
      }
      @include respond-to(large) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      grid-template-rows: 1fr;
    }
    .vehicle-model-links ul li {
      margin-top:0.25rem!important;
    }

    .vehicle-model-links li {
      margin-bottom: 0!important;
      margin-left: 20px;
      padding: 0;
      list-style-image: url('/tpl/_img/arrow_right_solo.gif');

      a:hover {
        background-color: #FCAB11;
        color: #1E262C;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
input.form-control,
select.form-control,
textarea.form-control {
  font-size: 14px;
  line-height: 16px;
  min-height: calc(1.5em + 0.75em + 5px) !important;

  &, .input-group-text {
    border-radius: 2px;
  }

}

// v1
@charset "UTF-8";

// 0. Font import
@import "media/fonts.css";

// 1. Configuration and helpers
@import 'abstracts/mixins',
'abstracts/variables',
'abstracts/functions';

// 2. Vendors
@import 'vendors/normalize',
'vendors/bootstrap',
'vendors/algoliaSearch';

// 3. Base stuff
@import 'base/base',
'base/typography',
'base/helpers';

// 4. Layout-related sections
@import 'layout/header',
'layout/footer',
'layout/breadcrums';

// 5. Components
@import 'components/button',
'components/navigation',
'components/lists',
'components/inputs',
'components/productCarousel',
'components/chooserWidget',
'components/carousel',
'components/modals';

// 6. Themes
@import 'themes/default';

// 7. Page-specific styles
@import 'pages/home',
'pages/product_detail',
'pages/product_listing',
'pages/contact';

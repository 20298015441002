footer {
  a {
    @extend %text-links;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin: 20px 0;
  }

  div.footer-nav-head {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    margin: 15px 0;
  }

  #footerCollapses {
    margin-top: 10px;
    padding: 10px 0;

    .footerCollapse {
      border-bottom: 1px solid $light-grey;
      padding: 20px 15px;
      @include respond-to(large) {
        border-bottom: 0 none;
      }

      ul {
        margin: 15px 0 0;
        padding: 0;

        li {
          list-style-type: none;
        }
      }

      #common, #brands {
        ul li:last-of-type {
          //font-weight: bold;
          //margin-top: 5px;
        }
      }

      #brands a {
        cursor: pointer;
        //text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }

      div.footer-nav-head {
        margin: 0;

        &, a {
          font-size: 16px;
          line-height: 19px;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
    @include respond-to(large) {
      & > .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #copyright {
    font-size: 12px;
    line-height: 17px;
    padding: 10px 0 50px;
    @include respond-to(large) {
      border-top: 1px solid $light-grey;
      padding: 40px;
      .col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.id-info {
  display:flex;
  justify-content: center;
  color: $mid-grey;
}
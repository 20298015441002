$text-font-stack: ('Roboto', 'Arial', sans-serif) !default;
$text-font-stack-alt: ('Work Sans', 'Arial', sans-serif);

$primary-color: #1E262C !default;
$brand-color: #FCAB11 !default;
$alt-color: #32B14C;
$light-grey: #DDE2E6 !default;
$mid-grey: #60666B !default;
$dark-grey: #1E262C !default;
$bg-color: #F4F6F8;
$brand-color-alt: #FF7901;
$info: #FFF2D8;

$max-width: 1180px !default;

$breakpoints: (
        'small': 576px,
        'medium': 768px,
        'large': 1180px
) !default;

.gradient_brand {
  @include linear-gradient($brand-color-alt, $brand-color);
}

.gradient_alt {
  @include linear-gradient(#32B14C, #39DC68);
}
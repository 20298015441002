
h1.slogan {
  font-size: 21px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 10px;
  @include respond-to-max(small) {
    margin: 0 0 10px;
  }
}

#address {
  color: $mid-grey;
}

#googleMapsButton {
  margin: 20px 0;

  @include respond-to(large) {
    float: right;
  }
}

#ourPremise {
  font-size: 19px;

  @include respond-to-max(small) {
    font-size: 14px;
  }

  line-height: 28px;
  margin-top: 30px;

  @include respond-to(large) {
    ul li {
      list-style: none;
      margin-bottom: 40px;
      margin-top: 30px;
    }
  }
}

#favorites {
  overflow: auto;
  padding: 20px 10px 30px;

  .row {
    margin-left: 0!important;
    margin-right: 0!important;
  }

  .favCategory li {
    margin-bottom: 5px;
    margin-left: 20px;
    padding: 0;
    list-style-image: url('/tpl/_img/arrow_right_solo.gif');
  }

  .hover-highlight {
    @include on-event {
      background-color: $brand-color!important;
    }

  }

  a {
    @extend %text-links2;
  }


  h4 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 10px;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    margin: 15px 0;
  }

  @include respond-to(large) {
    border-top: 1px solid $light-grey;
    margin-top: 10px;
    padding: 50px 0 20px;

    .favCategory {
      height: 270px;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        margin-bottom: 0;
        margin-left: 30px;
        & > div {
          height: 40px;
          line-height: 20px;
          margin-bottom: 5px;
          overflow-y: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.btn {
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  padding: 10px;

  &:hover, &.focus {
    border: 1px solid $primary-color;
  }
}

.btn-lg {
  font-size: 16px;
  line-height: 18px;
  padding: 12px 20px;
}

.btn-sm {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  padding: 6px 10px;
}

a.btn {
  text-decoration: none;
}

.btn-primary {
  @extend .gradient_brand;
  border: 1px solid white;
}

.btn-default {
  background-color: transparent;
  border: 1px solid $light-grey;
}

.btn-secondary {
  @extend .gradient_alt;
  border: 1px solid white;
  &:active, &:focus, &:visited {
    border: 1px solid white;
    color: white;
  }
}

.btn-dark {
  background-color: $primary-color;
  color: #ffffff;
}

#mobileMenu .btn-dark {
  color: $light-grey;
}

.btn-price {
  background-color: $light-grey;
  border: 1px solid rgba(30,38,44,0.15);
}
.carousel {

  .cRow {
    margin: 0 -10px;
    overflow: hidden;

    .slide {
      display: inline-block;

      .cArea {
        background-size: cover;
        display: grid;
        justify-content: center;
        width: 100%;

        .cTitle {
          background-color: $mid-grey;
          color: #FFFFFF;
          font-size: 17px;
          height: 50px;
          line-height: 50px;
          padding-left: 30px;
          padding-right: 7px;

          .btn {
            border: 1px solid transparent;
            float: right;
            font-weight: normal;
            margin-top: 6px;
            padding-left: 12px;
            padding-right: 12px;
          }
        }

        a {
          align-items: center;
        }
        img {
          height: 270px;
        }
      }
    }
  }

  .cArrow {
    cursor: pointer;
    font-size: 22px;
    height: 255px;
    line-height: 255px;
    position: absolute;
    width: 30px;
    z-index: 1000;

    span {
      background-color: #FFF;
      padding: 10px;
    }

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    @include respond-to(large) {
      span {
        background-color: transparent;
      }

      &.left {
        left: -20px;
      }

      &.right {
        right: -20px;
      }
    }
  }

}


.productCarouselList {
  .tns-controls {

    button {
      margin-top: 135px!important;
    }
  }
}
.productCarousel, .carousel {

  .tns-liveregion {
    display: none;
  }

  .tns-controls {

    button {
      @extend %arrows;
      background-color: #FFFFFF;
      font-size: 24px;
      line-height: 24px;
      margin-top: 120px;
      padding: 8px;
      position: absolute;
      z-index: 100;

      &[data-controls="next"] {
        border-width: 1px 0 1px 1px;
        right: 0;
      }

      &[data-controls="prev"] {
        border-width: 1px 1px 1px 0;
        left: 0;
      }

      @include respond-to(large) {
        & {
          border: 0 none;
          margin-top: 164px;
        }

        &[data-controls="next"] {
          right: -48px;
        }

        &[data-controls="prev"] {
          left: -48px;
        }
      }

      &[disabled] {
        display: none;
      }
    }
  }

}

.carousel .tns-outer > button[data-action="start"],
.carousel .tns-outer > button[data-action="stop"] {
  display: none;
}